/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useEffectOnce } from 'react-use';

import updateVisibleHeightOfPromoBanner from 'commons/updateVisibleHeightOfPromoBanner';
import setPromoBannerVisibleHeightCssVar from 'commons/setPromoBannerVisibleHeightCssVar';
import removeNull from 'commons/removeNull';
import useUserData from 'data-hooks/useUserData';
import getDisplayDate from './helpers/getDisplayDate';
import PromotionContent from './components/PromotionContent';

const PromotionBanner = ({ config = {} }) => {
  const defaultValuesRef = useRef();
  const [isPromoBannerVisible, setIsPromoBannerVisible] = useState(true);
  const [displayValue, setDisplayValue] = useState([]);
  const promoBannerRef = useRef();
  const { isTradeUser } = useUserData();

  const setTimeDiff = (endDate, dateFormat, interval) => {
    const timeDiff = endDate.getTime() - Date.now();

    if (timeDiff > 0) {
      const displayValueUpdated = getDisplayDate(timeDiff, dateFormat);
      setDisplayValue(displayValueUpdated);
    } else {
      clearInterval(interval);
    }
  };

  useEffectOnce(() => {
    defaultValuesRef.current = {
      backgroundColor: '#163a3e',
      textColor: '#dbeded',
      mainContent: '',
      subContent: '',
      endDate: new Date().toUTCString(),
      countDownFormat: 'D H M S',
      secondaryBannerText: null,
    };

    const endDate = new Date(
      config ? config.endDate : defaultValuesRef.current.endDate
    );

    const dateFormat = config
      ? config.countDownFormat
      : defaultValuesRef.current.countDownFormat;

    updateVisibleHeightOfPromoBanner();

    const updateHeightInterval = setInterval(() => {
      updateVisibleHeightOfPromoBanner();
    }, 500);

    const interval = setInterval(() => {
      setTimeDiff(endDate, dateFormat, interval);
    }, 1000);

    return () => {
      clearInterval(interval);
      clearInterval(updateHeightInterval);
    };
  });

  const hidePromoBar = () => {
    setPromoBannerVisibleHeightCssVar(0);
    setIsPromoBannerVisible(false);
  };

  const {
    backgroundColor,
    textColor,
    mainContent,
    subContent,
    countdownToggle,
    backgroundImage,
    backgroundHeight,
    topBannerText,
  } = config || defaultValuesRef.current;

  const promoStyleObj = {
    backgroundColor,
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : null,
    height: backgroundHeight
      ? `${
          backgroundHeight.endsWith('px')
            ? backgroundHeight
            : `${backgroundHeight}px`
        }`
      : null,
  };

  return !!mainContent && !!isPromoBannerVisible && !isTradeUser ? (
    <div
      data-xc="PromotionBanner"
      className="items-center bg-brand-dark flex min-h-[55px] relative w-full z-[1003] max-[767px]:min-h-[70px] max-[767px]:pt-[5px] max-[767px]:px-0 max-[767px]:pb-2.5"
      style={{
        ...removeNull(promoStyleObj),
      }}
      ref={promoBannerRef}
    >
      <PromotionContent
        countdownToggle={countdownToggle}
        displayValue={displayValue}
        hidePromoBar={hidePromoBar}
        isCategoryNecessary={false}
        mainContent={mainContent}
        subContent={subContent}
        textColor={textColor}
        topBannerText={topBannerText}
      />
    </div>
  ) : (
    <div className="PromotionBanner__filler" />
  );
};

PromotionBanner.propTypes = {
  config: PropTypes.shape({
    backgroundColor: PropTypes.string,
    countDownFormat: PropTypes.string,
    endDate: PropTypes.string,
    mainContent: PropTypes.string,
    topBannerText: PropTypes.string,
    subContent: PropTypes.string,
    textColor: PropTypes.string,
  }),
};

export default PromotionBanner;
