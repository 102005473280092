const getVisibleHeightOfElement = element => {
  if (!element) return 0;

  const heightOfElement = element.offsetHeight;
  if (heightOfElement === 0) return 0;

  const scrollPosition = Math.round(window.scrollY);
  const elementHeightOffset = heightOfElement - scrollPosition;
  const visibleElementHeight = Math.max(0, elementHeightOffset);

  return visibleElementHeight;
};

export default getVisibleHeightOfElement;
