import React from 'react';
import PropTypes from 'prop-types';

const PromotionSale = ({ displayValue, textColor }) => (
  <span className="flex flex-row items-center justify-start min-w-[200px] max-[767px]:inline max-[767px]:min-w-[auto] max-[767px]:mt-[5px]">
    <span
      className="text-[#dbeded]"
      style={{
        color: textColor,
      }}
    >
      &nbsp;Ends in&nbsp;
    </span>

    {displayValue.map(([value, unit], valueIndex) => (
      <span
        className="bg-brand-lighter rounded-[3px] text-[#163a3e] mx-[.2rem] my-0 px-[7px] py-0 max-[767px]:items-baseline max-[767px]:inline-flex"
        // eslint-disable-next-line react/no-array-index-key
        key={valueIndex}
      >
        <span className="text-base font-bold relative -top-px">{value}</span>
        <span className="text-xs font-bold ml-0.5 relative -top-px">
          {unit}
        </span>
      </span>
    ))}
  </span>
);

PromotionSale.propTypes = {
  displayValue: PropTypes.arrayOf(PropTypes.array),
  textColor: PropTypes.string,
};

export default PromotionSale;
