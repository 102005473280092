import getVisibleHeightOfElement from 'commons/getVisibleHeightOfElement';
import getPromoBannerElement from 'commons/getPromoBannerElement';
import setPromoBannerVisibleHeightCssVar from 'commons/setPromoBannerVisibleHeightCssVar';

const updateVisibleHeightOfPromoBanner = () => {
  const promoBannerElement = getPromoBannerElement();

  if (promoBannerElement) {
    const visibleHeight = getVisibleHeightOfElement(promoBannerElement);
    setPromoBannerVisibleHeightCssVar(visibleHeight);
  } else {
    setPromoBannerVisibleHeightCssVar(0);
  }
};

export default updateVisibleHeightOfPromoBanner;
