/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const PromotionText = ({
  mainContent,
  subContent,
  textColor,
  topBannerText,
}) => (
  <>
    <span
      className="PromotionText__main-content"
      style={{
        color: textColor,
      }}
    >
      <span dangerouslySetInnerHTML={{ __html: topBannerText || mainContent }} />
      {subContent && <span>&nbsp;</span>}
    </span>
    <span className="PromotionText__sub-content">{subContent}</span>
  </>
);

PromotionText.propTypes = {
  mainContent: PropTypes.string,
  subContent: PropTypes.node,
  textColor: PropTypes.string,
  topBannerText: PropTypes.string,
};

export default PromotionText;
