const MILLI = 1000;
const SECS = 1000 * 60;
const MINS = 1000 * 60 * 60;
const HOURS = 1000 * 60 * 60 * 24;

const getDaysDiff = timeDiff => Math.floor(timeDiff / HOURS);

const isLastDay = timeDiff => getDaysDiff(timeDiff) === 0;

// On the last day of a promotion, render the 'HH MM SS' date format that
// displays seconds
const getFinalDateFormat = (timeDiff, dateFormat) => {
  // To retain 'DD' in the date format, comment out the if statement below
  if (
    isLastDay(timeDiff) &&
    !dateFormat.includes('SS') &&
    dateFormat.startsWith('DD')
  ) {
    return 'HH MM SS';
  }

  return 'DD HH MM'; // Overriding format to match requirement
};

const getDisplayDate = (timeDiff, dateFormat) => {
  const finalDateFormat = getFinalDateFormat(timeDiff, dateFormat);

  // set days
  const daysDiff = getDaysDiff(timeDiff);

  // set hours
  let offset = timeDiff - daysDiff * HOURS;
  const hoursDiff = Math.floor(offset / MINS);

  // set minutes
  offset -= hoursDiff * MINS;
  const minDiff = Math.floor(offset / SECS);

  // set seconds
  offset -= minDiff * SECS;
  const secDiff = Math.floor(offset / MILLI);

  // set display value
  const output = [];
  const dateFormatArr = finalDateFormat.split(' ');

  dateFormatArr.forEach(format => {
    switch (format) {
      case 'DD':
        output.push([daysDiff, 'd']);
        break;

      case 'D':
        output.push([daysDiff, 'd']);
        break;

      case 'HH':
        output.push([hoursDiff, 'h']);
        break;

      case 'H':
        output.push([hoursDiff, 'h']);
        break;

      case 'MM':
        output.push([minDiff, 'm']);
        break;

      case 'M':
        output.push([minDiff, 'm']);
        break;

      case 'SS':
        output.push([secDiff, 's']);
        break;

      case 'S':
        output.push([secDiff, 's']);
        break;

      default:
      // No default
    }
  });

  return output;
};

export default getDisplayDate;
