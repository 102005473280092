import React from 'react';
import { logError } from 'commons/logger';
import PromotionBanner from 'components/consumer/promotion-banner';
import useContentfulPromotionContent from 'hooks/useContentfulPromotionContent';

const isDateLessThan5Days = dateString => {
  if (!dateString) {
    return false;
  }
  try {
    const date = new Date(dateString);
    const now = new Date();
    const difference = date.getTime() - now.getTime();
    if (difference < 5 * 24 * 3600 * 1000 && difference > 0) {
      return true;
    }
  } catch (err) {
    logError(err, 'Error while processing the endDate');
  }

  return false;
};

const PromotionBannerDynamic = () => {
  const { loading, promotionData } = useContentfulPromotionContent();

  if (loading) {
    return null;
  }

  const {
    alternateTitle,
    topBannerText,
    endDate,
    topBannerTextColor,
    topBannerBackgroundColor,
    topBannerBackgroundImage,
    secondaryBannerText,
  } = promotionData || {};

  const promotion = {
    name: alternateTitle,
    discount: null,
    mainContent: alternateTitle,
    topBannerText,
    subContent: null,
    endDate,
    countDownFormat: 'DD HH MM',
    countdownToggle: isDateLessThan5Days(endDate),
    backgroundImage: topBannerBackgroundImage,
    backgroundColor: topBannerBackgroundColor,
    backgroundHeight: '50px',
    secondaryBannerText,
    textColor: topBannerTextColor,
  };

  return <PromotionBanner config={promotion} />;
};

export default PromotionBannerDynamic;
