const setCssVar = (name, height) => {
  const val = `${height}px`;

  // If current value is the same as the new one, don't do anything
  if (document.documentElement.style.getPropertyValue(name) === val) {
    return;
  }

  document.documentElement.style.setProperty(name, val);
};

export default setCssVar;
