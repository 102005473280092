import React, { Component } from 'react';
import PropTypes from 'prop-types';

import IconClose from 'components/icons/iconClose';
import BrowserOnly from 'components/consumer/BrowserOnly';
import MobileOnly from 'components/consumer/MobileOnly';
import PromotionText from './PromotionText';
import PromotionSale from './PromotionSale';

class PromotionContent extends Component {
  componentDidMount() {
    this.animatePart1();
  }

  // Fade in specified part (transition takes 1750ms), then remain visible for
  // 4000ms before calling the callback
  fadeInPart(part, callback) {
    this.setState(
      {
        [part]: true,
      },
      () => {
        setTimeout(callback, 5750);
      }
    );
  }

  // Fade out specified part (transition takes 1750ms), then call the callback
  fadeOutPart(part, callback) {
    this.setState(
      {
        [part]: false,
      },
      () => {
        setTimeout(() => {
          callback();
        }, 1750);
      }
    );
  }

  animatePart1() {
    this.fadeInPart('part1', () => {
      this.fadeOutPart('part1', () => {
        this.animatePart2();
      });
    });
  }

  animatePart2() {
    this.fadeInPart('part2', () => {
      this.fadeOutPart('part2', () => {
        this.animatePart1();
      });
    });
  }

  render() {
    const {
      mainContent,
      subContent,
      displayValue,
      countdownToggle,
      hidePromoBar,
      textColor,
      topBannerText,
    } = this.props;

    return (
      <>
        <BrowserOnly>
          <MobileOnly>
            <button
              type="button"
              className="md:hidden [background:none] border-0 [&_svg_path]:fill-white focus-visible:outline-offset-[-5px] items-center cursor-pointer flex h-[30px] justify-center p-2.5 absolute right-0 top-0 w-[30px]"
              onClick={hidePromoBar}
            >
              <IconClose width="10" height="10" />
            </button>
          </MobileOnly>
        </BrowserOnly>

        <div className="text-[#dbeded] min-h-[50px] -top-px w-full flex items-center justify-center font-bold text-base max-[767px]:min-h-[60px] max-[767px]:text-center max-[767px]:text-sm [&_a]:text-gray-light5 [&_a]:underline">
          <BrowserOnly>
            {countdownToggle ? (
              <span className="flex absolute mx-auto my-0 left-0 right-0 justify-center items-center max-w-[70vw] max-[767px]:block max-[767px]:flex-col max-[767px]:max-w-[85vw] max-[767px]:leading-[1.8]">
                <PromotionText
                  mainContent={mainContent}
                  subContent={subContent}
                  textColor={textColor}
                  topBannerText={topBannerText}
                />
                <PromotionSale
                  displayValue={displayValue}
                  textColor={textColor}
                />
              </span>
            ) : (
              <span className="flex absolute mx-auto my-0 left-0 right-0 justify-center items-center max-w-[70vw] max-[767px]:block max-[767px]:flex-col max-[767px]:max-w-[85vw] max-[767px]:leading-[1.8]">
                <PromotionText
                  mainContent={mainContent}
                  subContent={subContent}
                  topBannerText={topBannerText}
                />
              </span>
            )}
          </BrowserOnly>
        </div>
      </>
    );
  }
}

PromotionContent.propTypes = {
  countdownToggle: PropTypes.bool.isRequired,
  displayValue: PropTypes.array.isRequired,
  hidePromoBar: PropTypes.func.isRequired,
  mainContent: PropTypes.string.isRequired,
  subContent: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  topBannerText: PropTypes.string,
};

export default PromotionContent;
